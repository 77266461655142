<template>
  <div class="publish-wrapper">
    <h3>{{text}}</h3>
    <el-form ref="ruleForm" :model="ruleForm" label-width="100px" width="300px" :rules="rules">
      <div class="divider">
        <div class="posts">
          <h4>请填写职位描述</h4>
          <el-form-item label="职位名称：" prop="jobName">
            <el-input v-model.trim="ruleForm.jobName" placeholder="请输入职位名称" class="names"></el-input>
          </el-form-item>
          <el-form-item label="职位类别：" prop="jobPost">
            <selectCategory
                class="names"
                codeType="job1"
                placeholder="请选择职位类别"
                :codeLevel="3"
                @setMValue="setjobValue($event,ruleForm,['jobTop','jobNext','jobPost'])"
                :defaultValue="[ruleForm.jobTop,ruleForm.jobNext,ruleForm.jobPost]"
            ></selectCategory>
          </el-form-item>

          <el-form-item label="职位类型：" prop="jobType">
            <el-radio-group v-model="ruleForm.jobType">
              <el-radio :label="n.value" v-for="(n,i) in jobTypeList" :key="i">{{n.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="招聘类型：" prop="isCollegestudent">
            <el-radio-group v-model="ruleForm.isCollegestudent" class="names">
              <el-radio :label="n.value" v-for="(n,i) in colleList" :key="i">{{n.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否急聘：" prop="isPressing">
            <el-radio-group v-model="ruleForm.isPressing" class="names">
              <el-radio :label="n.value" v-for="(n,i) in isUrgentList" :key="i">{{n.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="到岗时间：" v-if="isCity === '河南省' && ruleForm.isPressing === '0'">
            <el-select v-model="ruleForm.arrivalTime" placeholder="到岗时间" class="names">
              <el-option :label="n.ITEM_TEXT" :value="n.ITEM_VALUE" v-for="(n,i) in goTimeList" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位人数：" prop="jobNumber">
            <!--            <el-select v-model="ruleForm.jobNumber" placeholder="请选择职位人数" class="names">-->
            <!--              <el-option :label="n.label" :value="n.value" v-for="(n,i) in jobNumberList" :key="i"></el-option>-->
            <!--            </el-select>-->
            <el-input v-model="ruleForm.jobNumber" placeholder="请输入职位人数" class="names"></el-input> 人
          </el-form-item>
          <el-form-item label="职位描述：" prop="description">
            <Editor placeholder="请输入职位描述" @html="description" :testcontent="ruleForm.description"></Editor>
          </el-form-item>
          <el-form-item label="任职要求：" prop="postionRequier">
            <Editor
                placeholder="请输入职位要求"
                @html="postionRequier"
                :testcontent="ruleForm.postionRequier"
            ></Editor>
          </el-form-item>
          <el-form-item label="招聘时间：" required>
            <el-col :span="12">
              <el-form-item prop="beginTime">
                <el-date-picker
                    placeholder="开始日期"
                    v-model="ruleForm.beginTime"
                    type="date"
                    @change="getDate"
                    value-format="yyyy/MM/dd hh:mm:ss"
                    popper-class="eagle-a11y-uncut"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1" class="line">至</el-col>
            <el-col :span="11">
              <el-form-item prop="endTime">
                <el-date-picker
                    v-model="ruleForm.endTime"
                    placeholder="结束日期"
                    type="date"
                    @change="getDate"
                    value-format="yyyy/MM/dd hh:mm:ss"
                    popper-class="eagle-a11y-uncut"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <div class="works">
          <h4>请填写工作要求</h4>
          <el-form-item label=" 残疾类别：" prop="_distypes">
            <el-cascader
                v-model="ruleForm._distypes"
                :options="disableTypeList"
                :props="optionProps"
                placeholder="请选择残疾类别"
                clearable
                class="names "
                popper-class="selectDistype"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="经验要求：" prop="exp">
            <el-select v-model="ruleForm.exp" placeholder="请选择经验要求" class="names">
              <!-- v-if="item.ITEM_VALUE" -->
              <el-option
                  :label="item.ITEM_TEXT"
                  :value="item.ITEM_VALUE"
                  v-for="item in workExp"
                  :key="item.index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历要求：" prop="edu">
            <el-select v-model="ruleForm.edu" placeholder="请选择学历要求" class="names">
              <el-option
                  :label="item.ITEM_TEXT"
                  :value="item.ITEM_VALUE"
                  v-for="(item,index) in workEdu"
                  :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="薪资范围：" prop="jobMaxSalary" class="salary-con">
            <el-col :span="7">
              <div class="salary-item">
                <el-select :disabled="minSalaryOptions.length == 0" placeholder="请选择最低月薪"
                           @change="onminSalaryChange" v-model="ruleForm.jobMinSalary" class="names"
                           style="width: 100%">
                  <el-option v-for="item in minSalaryOptions" :key="item" :value="item">{{ item }}</el-option>
                </el-select>
                <span>元</span>
              </div>
            </el-col>
            <el-col :span="2" style="text-align: center">至</el-col>
            <el-col :span="7">
              <div class="salary-item">
                <el-select :disabled="maxSalaryOptions.length == 0" placeholder="请选择最高月薪"
                           @change="onmaxSalaryChange" v-model="ruleForm.jobMaxSalary" class="names"
                           style="width: 100%">
                  <el-option v-for="(item,index) in maxSalaryOptions" :key="index" :value="item">{{ item }}
                  </el-option>
                </el-select>
                <span>元</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="ml16">
                <el-radio-group v-model="ruleForm.jobSalary" @change="onChangeJobSalary">
                  <el-radio label="30" border>面议</el-radio>
                </el-radio-group>
              </div>
            </el-col>
          </el-form-item>
          <el-form-item label="工作地点：" prop="cityid">
            <ThreeLink
                class="names"
                placeholder="请选择工作地址"
                :codeType="'provinceid'"
                :codeLevel="'-1'"
                @setMValue="setScasValue($event,ruleForm,['provinceid','cityid','threeCityid'])"
                :defaultValue="[ruleForm.provinceid,ruleForm.cityid,ruleForm.threeCityid]"
            ></ThreeLink>
          </el-form-item>
          <el-form-item prop="address" label="详细地址：">
            <el-input v-model.trim="ruleForm.address" placeholder="请输入具体地址" class="names"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="tags">
      <h4>职位标签</h4>
      <p>从以下标签中选择</p>
      <small class="color99 marL10">（最多可选择5个标签，不允许重复）</small>
      <div class="form">
        <el-input
            v-model="inputLabel"
            type="text"
            maxlength="5"
            placeholder="请输入职位标签"
            class="w400 marL10 bordeEd img marR10"
        ></el-input>
        <el-button
            class="bs_btn bs_text_oo el-button_border_ededed"
            icon="el-icon-plus"
            @click="addLablest"
        >添加</el-button>
        <div class="box marL10 marT20">
          <div class="top">
            <button
                class="bs_btn"
                v-for="(item,index) in ruleForm.labels"
                :key="index"
                @click="hasCheck(index,item)"
            >{{item}}</button>
          </div>
          <div class="bottom">
            <button
                class="bs_btn"
                v-for="(item,index) in hasLables"
                :key="index"
                @click="noCheckd(index,item)"
            >{{item}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="submits">
      <el-button type="primary" @click="publishSubmit('ruleForm')" :loading="loading">{{text2}}</el-button>
    </div>
  </div>
</template>

<script>
import Editor from "components/public/editor";
import ThreeLink from "components/selectComponents/ThreeLink";
import selectCategory from "components/selectComponents/selectCategory";
let salaryOptions = [
  '1千',
  '2千',
  '3千',
  '4千',
  '5千',
  '6千',
  '7千',
  '8千',
  '9千',
  '1万',
  '1.5万',
  '1.5万以上'
];
export default {
  components: {
    Editor,
    ThreeLink,
    selectCategory
  },
  inject:["reload"],
  data() {
    return {
      id:'',
      loading:false,
      text: "",
      isCity:'',
      optionProps: {
        multiple: true
        // checkStrictly: true
      },
      label: [],
      hasLables: [
        "责任心",
        "执行力",
        "个人能力",
        "沟通协调能力",
        "五险一金",
        "双休"
      ],
      colleList: [
        {
          label: "社招",
          value: "0"
        },
        {
          label: "校招(只招残疾人大学生)",
          value: "1"
        }
      ],
      isUrgentList: [
        {
          label: "是",
          value: "1"
        },
        {
          label: "否",
          value: "0"
        }
      ],
      inputLabel: "",
      workEdu: [],
      jobTypeList: [],
      industryType: [],
      industryTypes: [],
      disableTypeList: [],
      workExp: [],
      monthMoney: [],
      jobNumberList: [],
      category: [],
      goTimeList:[],
      ruleForm: {
        jobName: "",
        jobTop: "",
        jobNext: "",
        jobPost: "",
        jobType: "",
        isCollegestudent: "",
        isPressing: "",
        jobNumber: "",
        description: "",
        postionRequier: "",
        beginTime: "",
        endTime: "",
        _distypes: [],
        edu: "",
        exp: "",
        labels: [],
        jobSalary: "",
        provinceid: "",
        cityid: "",
        threeCityid: "",
        address: "",
        major: "",
        pageNum: 1,
        pageSize: 6,
        jobDistypeInDTOList: [],
        arrivalTime:'',
      },
      rules: {
        jobName: [
          {
            required: true,
            message: "请输入职位名称，不能和已发布的重复",
            trigger: "blur"
          }
        ],
        jobType: [
          {
            required: true,
            message: "请选择职位类型",
            trigger: "change"
          }
        ],
        jobPost: [
          { required: true, message: "请选择职位类别", trigger: "change" }
        ],
        isCollegestudent: [
          { required: true, message: "请选择招聘类型", trigger: "change" }
        ],
        isPressing: [
          { required: true, message: "请选择招聘类型", trigger: "change" }
        ],
        jobNumber: [
          { required: true, message: "请选择招聘人数", trigger: "change" }
        ],
        jobSalary: [
          { required: true, message: "请选择职位薪资", trigger: "change" }
        ],
        cityid: [
          { required: true, message: "请选择工作地点", trigger: "change" }
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "change" }
        ],
        edu: [{ required: true, message: "请选择学历", trigger: "change" }],
        exp: [{ required: true, message: "请选择工作经验", trigger: "change" }],
        _distypes: [
          { required: true, message: "请选择 残疾类别", trigger: "change,blur" }
        ],
        major: [
          { required: true, message: "请选择专业要求", trigger: "change" }
        ],
        beginTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change"
          }
        ],
        endTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change"
          }
        ],
      },
      minSalaryOptions: [...salaryOptions],
      maxSalaryOptions: [...salaryOptions.slice(4)],
    };
  },
  watch: {
    ruleForm: {
      handler(newName, oldName) {
        // this.info();
        console.log(newName.jobName, oldName.jobName.trim(),999)
      },
      deep: true
    },
    $route(to,from){
      console.log(to,from)
      if(this.$route.query.id){
        this.id = this.$route.query.id;
        this.getPostInfo()
      }
    }
  },

  methods: {
    // 请求接口
    publishSubmit(ruleForm) {
      this.ruleForm.postList = this.label;
      this.loading = true;
      this.$refs[ruleForm].validate(async valid => {
        this.loading = false;
        let dis_types = this.ruleForm._distypes;
        let postArr = [];
        for (let i = 0; i < dis_types.length; i++) {
          let y = dis_types[i];
          let postObj = {};
          if (y[0]) {
            postObj["disType"] = y[0];
          }
          if (y[1]) {
            postObj["disTypeLevel"] = y[1];
          }
          postArr.push(postObj);
        }
        if(this.ruleForm.description .length < 19){
          this.$message({
            type: "error",
            message: "职位描述字数不能少于10个字符!"
          });
          return
        }
        if(this.ruleForm.postionRequier.length < 19){
          this.$message({
            type: "error",
            message: "任职要求字数不能少于10个字符!"
          });
          return
        }
        this.ruleForm["jobDistypeInDTOList"] = postArr;
        if (valid) {
          let that = this;
          let res = await that.$api.publishList(that.ruleForm);
          this.loading = false;
          if (res.data.code == 200) {
            // this.$message({
            //   message: res.data.msg,
            //   type: "success"
            // });
            if (this.id != null && this.id != undefined) {
              this.$notify({
                title: "成功",
                message: "修改职位成功！",
                type: "success"
              });
              that.$router.push("/enterprise/post/");
            } else {
              this.$confirm("发布成功, 是否继续?", "提示", {
                confirmButtonText: "继续发布",
                cancelButtonText: "返回职位管理",
                type: "warning"
              })
                  .then(() => {
                    this.$message({
                      type: "success",
                      message: "请继续填写新职位!"
                    });
                    this.reload();
                  })
                  .catch(() => {
                    that.$router.push("/enterprise/post/");
                  });
            }
          }else{
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        } else {
          this.loading = false;
          this.$message({
            message: "信息项未填写完整！",
            type: "warning"
          });
          console.log("信息项不全!!");
          return false;
        }
      });
    },
    // 编辑数据
    async getPostInfo() {
      // this.ruleForm.postList = this.label;
      let res = await this.$axios.get(
          "/api/app-jycy-job/getJobEditDetailByid",
          {
            id: this.id
          }
      );
      if (res.data.success) {
        this.ruleForm = res.data.data;
        this.ruleForm.labels = [];
        for (let i = 0; i < this.ruleForm.jobLabels.length; i++) {
          this.ruleForm.labels.push(this.ruleForm.jobLabels[i].labelName);
        }
        if (this.ruleForm.jobClobOutDTO){
          this.ruleForm.description = this.ruleForm.jobClobOutDTO.jobDescript;
          this.ruleForm.postionRequier = this.ruleForm.jobClobOutDTO.jobRequirement;
        }
        if (this.ruleForm.distype) {
          let a = [];
          for (let i = 0; i < this.ruleForm.distype.length; i++) {
            let x = this.ruleForm.distype[i];
            let y = [];
            if (x["disType"]) {
              y.push(x["disType"]);
            }
            if (x["disTypeLevel"]) {
              y.push(x["disTypeLevel"]);
            }
            a.push(y);
          }
          this.ruleForm._distypes = a;
        }
      }
    },
    //职位类别-三级联动
    setjobValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    //省市区三级联动
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    //招聘日期
    getDate(){
      this.getTimes(this.ruleForm.beginTime,this.ruleForm.endTime);
    },
    getTimes(start,end) {
      let sdate = new Date(start);
      let now = new Date(end);
      let days = now.getTime() - sdate.getTime();
      let day = Math.floor(days / (1000 * 60 * 60 * 24));
      if(day < 3){
        this.$message({
          message: '离招聘截止日期已不足3天',
          type: "warning"
        });
        this.ruleForm.beginTime = '';
        this.ruleForm.endTime = '';
        return
      }
    },
    setScasValued(value) {
      this.ruleFormprojectCountry = value;
    },
    //下拉
    selects() {
      let that = this;
      // 残疾类别
      that.$axios
          .get("/api/app-jycy-sysdict/getCodeValue", {
            dictType: "disabled_class"
          })
          .then(res => {
            if (res.data.success) {
              that.disableTypeList = res.data.data.map(v => ({
                value: v.value,
                label: v.label,
                children: v.children.map(u => ({
                  value: u.value,
                  label: u.label
                }))
              }));
            }
          });
      // 职位类型
      that.$axios
          .get("/api/app-jycy-sysdict/getCodeValue", {
            dictType: "job_type"
          })
          .then(res => {
            if (res.data.success) {
              that.jobTypeList = res.data.data;
            }
          });
      // 招聘人数
      that.$axios
          .get("/api/app-jycy-sysdict/getCodeValue", {
            dictType: "job_number"
          })
          .then(res => {
            if (res.data.success) {
              that.jobNumberList = res.data.data;
            }
          });
      //职位特色
      that.$axios
          .get("/api/app-jycy-sysdict/queryDictTextByDiCode", {
            code: "job_tese"
          })
          .then(res => {
            if (res.data.success) {
              that.welfare = res.data.data;
            }
          });
      //月薪范围
      that.$axios
          .get("/api/app-jycy-sysdict/queryDictTextByDiCode", {
            code: "user_salary"
          })
          .then(res => {
            if (res.data.success) {
              this.monthMoney = res.data.data;
            }
          });
      //公司类型
      that.$axios
          .get("/api/app-jycy-sysdict/queryDictTextByDiCode", {
            code: "pr"
          })
          .then(res => {
            if (res.data.success) {
              that.companyType = res.data.data;
            }
          });
      //职位类型
      that.$axios
          .get("/api/app-jycy-sysdict/queryDictTextByDiCode", {
            code: "job_type"
          })
          .then(res => {
            if (res.data.success) {
              that.workType = res.data.data;
            }
          });
      //工作经验
      that.$axios
          .get("/api/app-jycy-sysdict/queryDictTextByDiCode", {
            code: "user_word"
          })
          .then(res => {
            if (res.data.success) {
              that.workExp = res.data.data;
            }
          });
      //学历要求
      that.$axios
          .get("/api/app-jycy-sysdict/queryDictTextByDiCode", {
            code: "user_edu"
          })
          .then(res => {
            if (res.data.success) {
              that.workEdu = res.data.data;
            }
          });
      //到岗时间
      this.$axios.get(
          "/api/app-jycy-sysdict/queryDictTextByDiCode",
          {code: "arrival_time"}
      ).then(red =>{
        if (red.data.success){

          that.goTimeList = red.data.data;
        }
      })
    },
    industry() {
      let that = this;
      this.$axios.get("/api/app-jycy-sysdict/getHyTwoList").then(res => {
        if (res.data.success) {
          that.industryType = res.data.data;
        }
      });
    },
    industryChild(items, value) {
      this.industryTypes = items;
      this.search.show2 = true;
    },
    // 富文本传递数据
    description(val) {
      this.ruleForm.description = val;
    },
    postionRequier(val) {
      this.ruleForm.postionRequier = val;
    },
    // 添加标签
    addLablest() {
      if (this.ruleForm.labels.length >= 5) {
        this.$message.error("最多添加5个");
        return;
      }
      if (this.ruleForm.labels.length < 5 && this.inputLabel != "") {
        this.ruleForm.labels.push(this.inputLabel);
        this.inputLabel = "";
        let arr = this.ruleForm.labels;
        for (let i = 0; i < arr.length; i++) {
          for (let k = i + 1; k < arr.length; k++) {
            if (arr[i] == arr[k]) {
              arr.splice(k, 1);
              k--;
            }
          }
        }
        this.ruleForm.labels = arr;
      }
    },
    hasCheck(index, name) {
      this.hasLables.push(name);
      this.ruleForm.labels.splice(index, 1);
      let arr = this.hasLables;
      for (let i = 0; i < arr.length; i++) {
        for (let k = i + 1; k < arr.length; k++) {
          if (arr[i] == arr[k]) {
            arr.splice(k, 1);
            k--;
          }
        }
      }
      this.hasLables = arr;
    },
    noCheckd(index, name) {
      if (this.ruleForm.labels.length >= 5) {
        this.$message.error("最多添加5个");
        return;
      }
      this.ruleForm.labels.push(name);
      this.hasLables.splice(index, 1);
      let arr = this.ruleForm.labels;
      for (let i = 0; i < arr.length; i++) {
        for (let k = i + 1; k < arr.length; k++) {
          if (arr[i] == arr[k]) {
            arr.splice(k, 1);
            k--;
          }
        }
      }
      this.ruleForm.labels = arr;
    },
    onChangeJobSalary() {
      (this.ruleForm.jobMinSalary = ''), (this.ruleForm.jobMaxSalary = '');
    },
    onminSalaryChange(val) {
      let index = salaryOptions.indexOf(val);
      this.maxSalaryOptions = salaryOptions.slice(index + 1);
      this.ruleForm.jobSalary = '';
    },
    onmaxSalaryChange(val) {
      let index = salaryOptions.indexOf(val);
      if (val == '1.5万以上') {
        this.minSalaryOptions = [];
        this.ruleForm.jobMinSalary = '';
      } else {
        this.minSalaryOptions = salaryOptions.slice(0, index);
      }
      this.ruleForm.jobSalary = '';
    },
  },
  mounted() {
    this.selects();
    this.industry();
    // this.getPostInfo();
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.text = "编辑职位";
      this.text2 = "保存";
      this.getPostInfo();
    } else {
      this.text = "发布职位";
      this.text2 = "发布";
    }
    this.isCity = localStorage.getItem('locationSite');
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};
</script>

<style lang="less" >
@import "~assets/css/enterprise/home/publish.less";
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.names {
  width: 305px;
}


.timing {
  width: 149px;
  margin-left: -10px;
}
.line {
  padding-right: 10px;
}

.w305 {
  width: 305px;
  height: 123px !important;
}

.el-input__inner {
  height: 36px;
}
.checkbox {
  font-size: 14px;
  color: #999999;
  padding-left: 100px;
}
.facing {
  color: #333333;
  font-size: 14px;
}
.places {
  height: 52px;
}

//更改残疾类别样式
.selectDistype {
  .el-cascader-panel {
    .el-scrollbar:nth-of-type(1) {
      .el-scrollbar__view {
        .el-cascader-node {
          .el-checkbox {
            display: none;
          }
        }
      }
    }
  }
}
.salary-con {
  .salary-item {
    position: relative;
    .el-input__suffix {
      display: none;
    }
    & > span {
      position: absolute;
      color: #787e7b;
      right: 2px;
      width: 24px;
      color: #787e7b;
      height: 36px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.ml16{
  margin-left:16px;
  .el-radio-group {
    .el-radio {
      padding: 0;
      width: 68px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-radio__input {
        opacity: 0;
        display: none;
      }

      .el-radio__label {
        padding: 0;
        color: #4b534f;
        font-size: 16px;
        font-weight: normal;
      }
    }

    .is-checked {
      background: #28a46d;
      border-radius: 5px;

      .el-radio__label {
        color: white;
        font-size: 16px;
      }
    }
  }
}
</style>
